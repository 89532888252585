import partlistService from "@/services/partlist.service";

const state = {
  partList: [],
  part: [],
  selectedParts: [],
  partsSettings: [],
  quickParts: [],
  interchange: [],
  imageResponse: [],
  vinSearch: [],
  partData: {},
  partNamesData: [],
  make: [],
  model: [],
  year: [],
  trim: [],
  mediaImageResponse: [],
  carPart: {},
  auctionPart: {},
  addCheckoutPartInfo: {}
};

const actions = {
  getPartList({ commit }, payload) {
    return partlistService.getPartList(commit, payload);
  },

  uploadImage({ commit }, payload) {
    return partlistService.uploadImage(commit, payload);
  },

  addParts({ commit }, payload) {
    return partlistService.addParts(commit, payload);
  },

  addAuctionParts({ commit }, payload) {
    return partlistService.addAuctionParts(commit, payload);
  },

  addDirectParts({ commit }, payload) {
    return partlistService.addDirectParts(commit, payload);
  },

  vinSearch({ commit }, data) {
    return partlistService.vinSearch(commit, data);
  },

  deleteParts({ commit }, payload) {
    return partlistService.deleteParts(commit, payload);
  },

  deleteSelectedParts({ commit }, payload) {
    return partlistService.deleteSelectedParts(commit, payload);
  },

  deleteCarPart({ commit }, payload) {
    return partlistService.deleteCarPart(commit, payload);
  },

  deleteThirdPartyParts({ commit }, payload) {
    return partlistService.deleteThirdPartyParts(commit, payload);
  },

  getPartsById({ commit }, payload) {
    return partlistService.getPartsById(commit, payload);
  },

  getInterchange({ commit }, payload) {
    return partlistService.getInterchange(commit, payload);
  },

  updateVehicleParts({ commit }, payload) {
    return partlistService.updateVehicleParts(commit, payload);
  },

  getPartNames({ commit }, payload) {
    return partlistService.getPartNames(commit, payload);
  },

  getPart({ commit }, payload) {
    return partlistService.getPart(commit, payload);
  },

  getCarPart({ commit }, payload) {
    return partlistService.getCarPart(commit, payload);
  },

  getSelectedPart({ commit }) {
    return partlistService.getSelectedPart(commit);
  },

  updateSelectedParts({ commit }, payload) {
    return partlistService.updateSelectedParts(commit, payload);
  },

  getPartsSetting({ commit }, payload) {
    return partlistService.getPartsSetting(commit, payload);
  },

  getQuickParts({ commit }, payload) {
    return partlistService.getQuickParts(commit, payload);
  },

  getMake({ commit }, query) {
    return partlistService.getMake(commit, query);
  },

  getModel({ commit }, query) {
    return partlistService.getModel(commit, query);
  },

  getYear({ commit }, query) {
    return partlistService.getYear(commit, query);
  },

  getTrim({ commit }, query) {
    return partlistService.getTrim(commit, query);
  },

  getPType({ commit }) {
    return partlistService.getPType(commit);
  },

  uploadMediaImage({ commit }, payload) {
    return partlistService.uploadMediaImage(commit, payload);
  },

  addCheckoutPart({ commit }, payload) {
    return partlistService.addCheckoutPart(commit, payload);
  }
};

const mutations = {
  setPartList(state, response) {
    state.partList = response.data;
  },

  setImageResponse(state, response) {
    state.imageResponse = response.data;
  },

  setVinSearchData(state, response) {
    state.vinSearch = response.data;
  },

  setPartById(state, response) {
    state.partData = response.data;
  },

  setInterchange(state, response) {
    state.interchange = response.data;
  },

  setPartNames(state, response) {
    state.partNamesData = response.data;
  },

  setPart(state, response) {
    state.part = response.data;
  },

  setCarPart(state, response) {
    state.carPart = response.data;
  },

  setAuctionPart(state, response) {
    state.auctionPart = response.data;
  },

  setSelectedParts(state, response) {
    state.selectedParts = response.data;
  },

  setPartsSettings(state, response) {
    state.partsSettings = response.data;
  },

  setQuickParts(state, response) {
    state.quickParts = response.data;
  },

  setMake(state, response) {
    state.make = response.data;
  },

  setModel(state, response) {
    state.model = response.data;
  },

  setYear(state, response) {
    state.year = response.data;
  },

  setTrim(state, response) {
    state.trim = response.data;
  },

  setPType(state, response) {
    state.pType = response.data;
  },

  setMediaImageResponse(state, response) {
    state.mediaImageResponse = response.data;
  },

  setAddCheckoutPart(state, response) {
    state.addCheckoutPartInfo = response;
  }
};

const getters = {
  getPartList(state) {
    return state.partList;
  },

  getImageResponse(state) {
    return state.imageResponse;
  },

  getVinSearchData(state) {
    return state.vinSearch;
  },

  getPartById(state) {
    return state.partData;
  },

  getInterchange(state) {
    return state.interchange;
  },

  getPartNames(state) {
    return state.partNamesData;
  },

  getPart(state) {
    return state.part;
  },

  getCarPart(state) {
    return state.carPart;
  },

  getAuctionPart(state) {
    return state.auctionPart;
  },

  getSelectedParts(state) {
    return state.selectedParts;
  },

  getPartsSettings(state) {
    return state.partsSettings;
  },

  getQuickParts(state) {
    return state.quickParts;
  },

  getMake(state) {
    return state.make;
  },

  getModel(state) {
    return state.model;
  },

  getYear(state) {
    return state.year;
  },

  getTrim(state) {
    return state.trim;
  },

  getPType(state) {
    return state.pType;
  },

  getMediaImageResponse(state) {
    return state.mediaImageResponse;
  },

  getAddCheckoutPart(state) {
    return state.addCheckoutPartInfo;
  }
};

export const partlist = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
